<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol xl="12">
						<AdminForm
							is-update
							@apply-form="updateAdminById"
							@cancel-form="goToAdminList"
							@change-pwd="changePassword"
							@delete-form="deleteAdminById"
						></AdminForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import {
	GET_ADMIN_BY_ID,
	UPDATE_ADMIN_BY_ID,
	DELETE_ADMIN_BY_ID,
	UPDATE_ADMIN_PASSWORD,
	GET_CLINIC,
} from "./../store/action-types"
import { GET_USER_LOGIN_PROFILE } from "./../../authentication/store/action-types"
import { mapActions } from "vuex"

export default {
	name: "UpdateAdmin",

	components: {
		AdminForm: () => import("./../components/AdminForm.vue"),
	},

	props: {
		id: {
			type: [String, Number],
			default: null,
		},
	},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {
		this.GET_ADMIN_BY_ID({ id: this.id })
	},

	methods: {
		...mapActions("admins", {
			GET_ADMIN_BY_ID,
			UPDATE_ADMIN_BY_ID,
			DELETE_ADMIN_BY_ID,
			UPDATE_ADMIN_PASSWORD,
			GET_CLINIC,
		}),
		...mapActions("authentication", { GET_USER_LOGIN_PROFILE }),

		updateAdminById: async function (params) {
			await this.UPDATE_ADMIN_BY_ID(params)
			if (params.isCurrentUser) this.GET_USER_LOGIN_PROFILE()
		},

		deleteAdminById: async function (params) {
			await this.DELETE_ADMIN_BY_ID(params)
		},

		changePassword: async function (params) {
			await this.UPDATE_ADMIN_PASSWORD(params)
		},

		goToAdminList() {
			this.$router.push({ name: "AdminList" })
		},
	},
}
</script>

<style lang="scss" scoped></style>
